<template>
  <div class="from_outer">
    <div>
      <el-form label-width="120px" ref="formName" :model="formLabelAlign">
        <el-form-item
          label="危险源名称："
          prop="name"
          :rules="[{ required: true, message: '请输入危险源名称' }]"
        >
          <el-input
            placeholder="请输入危险源名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="风险成因："
          prop="danger"
          :rules="[{ required: true, message: '请输入风险成因' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入风险成因"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.causes"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="导致后果："
          prop="danger"
          :rules="[{ required: true, message: '请输入导致后果' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入导致后果"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.danger"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="管控措施："
          prop="control"
          :rules="[{ required: true, message: '请输入管控措施' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入管控措施"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.control"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="应急预案："
    
        >
          <el-input
            type="textarea"
            placeholder="请输入应急预案"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.plans"
          ></el-input>
        </el-form-item> -->

        <el-form-item label="评估标准：">
          <el-select
            v-model="formLabelAlign.level_type"
            placeholder="请选择评估标准"
          >
            <el-option
              v-for="item in estimate_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="危险等级：">
          <el-select
            v-model="formLabelAlign.level_color"
            placeholder="请选择危险等级"
          >
            <el-option
              v-for="item in color_level"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="分类：" prop="cate_id">
          <el-cascader
            class="form-inp"
            v-model="formLabelAlign.cate_id"
            :options="bepartment_Data"
            :props="{
              checkStrictly: true,
              label: 'name',
              children: 'items',
              value: 'id',
            }"
            @change="dataChange"
          ></el-cascader>
        </el-form-item>

        <!-- <el-form-item
          label="考核分数"
          prop="score"
          :rules="[{ required: true, message: '考核分数不能为空' }]"
        >
          <el-input
            placeholder="考核分数"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.score"
          ></el-input>
        </el-form-item> -->

        <el-form-item label="危险源标签：">
          <div class="tag-sty">
            <div>
              <el-tag
                v-for="(tag, index) in formLabelAlign.tags_list"
                :key="index"
                style="margin: 0 5px 10px 0"
                closable
                @close="on_tabclose(index)"
              >
                {{ tag.name }}
              </el-tag>
            </div>
          </div>
          <div>
            <el-button @click="label_show = true" type="primary"
              >添加风险源标签</el-button
            >
          </div>
        </el-form-item>

        <el-form-item label="警示图片：">
          <div class="tag-sty">
            <div
              class="img-div"
              v-for="(src, index) in formLabelAlign.icons_list"
              :key="index"
            >
              <el-image
                :src="src.icon"
                class="pop-img"
                closable
                :preview-src-list="[src.icon]"
              />

              <i
                @click="on_imgdelete(index)"
                class="el-icon-circle-close sty-imgdelete"
              ></i>
            </div>
          </div>
          <div>
            <el-button @click="caution_show = true" type="primary"
              >添加警示图片</el-button
            >
          </div>
        </el-form-item>

        <!-- <el-form-item
          label="是否启用："
          prop="is_enable"
          :rules="[{ required: true, message: '请选择是否启用' }]"
        >
          <el-switch
            v-model="formLabelAlign.is_enable"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item> -->
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
      <!-- 风险源标签 -->
      <el-dialog width="80%" title="风险源标签" :visible.sync="label_show" show-close>
        <div class="from_submit">
          <el-form label-width="120px" :model="label_from" class="from">
            <el-form-item label="用户ID：">
              <el-input
                placeholder="请输入用户ID"
                size="mini"
                class="pop-inp"
                v-model="label_from.id"
              ></el-input>
            </el-form-item>
            <el-form-item label="风险源名称：">
              <el-input
                placeholder="请输入风险源名称"
                size="mini"
                class="pop-inp"
                v-model="label_from.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_labelList(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                @click="on_reset(label_from)"
                icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
        <el-table
          ref="labeltable"
          highlight-current-row
          height="300px"
          :data="label_List"
          :header-cell-style="{ background: '#f8f8f9' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="标签名"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                class="list_sty"
                type="primary"
                @click="on_addlabel(scope.row)"
                >添加</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @current-change="on_change"
            :current-page="label_from.page"
            :page-size="label_from.page_size"
            :total="label_total"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <!-- 警示图片 -->
      <el-dialog width="80%" title="警示图片" :visible.sync="caution_show" show-close>
        <div class="from_submit">
          <el-form label-width="120px" :model="caution_from" class="from">
            <el-form-item label="用户ID：">
              <el-input
                placeholder="请输入用户ID"
                size="mini"
                class="pop-inp"
                v-model="caution_from.id"
              ></el-input>
            </el-form-item>
            <el-form-item label="警示图片名称：">
              <el-input
                placeholder="请输入警示图片名称"
                size="mini"
                class="pop-inp"
                v-model="caution_from.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_cautionList(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                @click="on_reset(caution_from)"
                icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
        <el-table
          ref="labeltable"
          highlight-current-row
          height="300px"
          :data="caution_List"
          :header-cell-style="{ background: '#f8f8f9' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="标签名"> </el-table-column>
          <el-table-column label="警示图片">
            <template slot-scope="scope">
              <el-image style="width: 40px; height: 40px" :src="scope.row.icon">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                class="list_sty"
                type="primary"
                @click="on_addcaution(scope.row)"
                >添加</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @current-change="on_changecaution"
            :current-page="caution_from.page"
            :page-size="caution_from.page_size"
            :total="caution_total"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  saveInfo,
  tagsindex,
  iconindex,
  pointsave,
} from "../../assets/request/api";
export default {
  name: "risk_sourceredact",
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      formLabelAlign: {
        name: "",
        danger: "",
        control: "",
        plans: "",
        is_enable: "",
        causes: "",
        tags_list: [],
        icons_list: [],
        level_type: "",
        level_color: "",
        score: 0,
        cate_id: "",
      },
      //标签所需参数和列表
      label_List: [],
      label_from: {
        id: "",
        name: "",
        is_enable: "1",
        page_size: 10,
        page: 1,
      },
      label_total: 0,
      label_show: false,
      //当前选择的标签
      label_select: [],
      //警告图片所需参数和列表
      caution_List: [],
      caution_from: {
        id: "",
        name: "",
        is_enable: "1",
        page_size: 10,
        page: 1,
      },
      caution_total: 0,
      caution_show: false,
      //当前选择的图片
      caution_select: [],
      // 下拉列表
      estimate_type: [],
      color_level: [],
      //分类
      bepartment_Data: [],
    };
  },
  created() {
    let { id } = this.$route.query;

    this.way_shiyan(id);

    this.way_labelList();
    this.way_cautionList();
  },
  methods: {
    way_shiyan(id) {
      saveInfo({ id: id }).then((res) => {
        let { formLabelAlign } = this;
        this.bepartment_Data = res.data.cate;
        this.color_level = res.data.color_level;
        this.estimate_type = res.data.estimate_type;
        if (id) {
          let {
            name,
            danger,
            control,
            plans,
            is_enable,
            tags_list,
            icons_list,
            level_color,
            level_type,
            causes,
            score,
          } = res.data.info;
          formLabelAlign.name = name;
          formLabelAlign.id = id;
          formLabelAlign.danger = danger;
          formLabelAlign.control = control;
          formLabelAlign.plans = plans;
          formLabelAlign.is_enable = is_enable;
          formLabelAlign.tags_list = tags_list;
          formLabelAlign.icons_list = icons_list;
          formLabelAlign.level_color = level_color;
          formLabelAlign.level_type = level_type;
          formLabelAlign.causes = causes;
          formLabelAlign.score = score;
          this.type_name = "修改";
        }
      });
    },
    //风险源标签
    way_labelList(type) {
      if (type) {
        this.label_from.page = 1;
      }
      let { label_from } = this;
      tagsindex(label_from).then((res) => {
        this.label_total = res.data.total;
        this.label_List = res.data.data;
      });
    },
    //警示图片
    way_cautionList(type) {
      if (type) {
        this.label_from.page = 1;
      }
      let { caution_from } = this;
      iconindex(caution_from).then((res) => {
        this.caution_total = res.data.total;
        this.caution_List = res.data.data;
      });
    },
    //去除当前重复的信息
    way_ispitchlabel(data, alreadyData) {
      let Lists = data.filter((item) => {
        return alreadyData.some((el) => el.id == item.id);
      });
      return Lists;
    },
    //添加标签
    on_addlabel(row) {
      console.log(row);
      let { tags_list } = this.formLabelAlign;
      let is_join = tags_list.some((item) => {
        return item.id == row.id;
      });
      if (is_join) {
        this.$message({
          message: "当前风险源标签已添加，请勿重复添加",
          type: "warning",
        });
      } else {
        tags_list.push(row);
        this.$message({
          message: "添加风险源标签成功",
          type: "success",
        });
      }
    },
    //删除标签
    on_tabclose(index) {
      this.formLabelAlign.tags_list.splice(index, 1);
    },
    //添加图片
    on_addcaution(row) {
      console.log(row);
      let { icons_list } = this.formLabelAlign;
      let is_join = icons_list.some((item) => {
        return item.id == row.id;
      });
      if (is_join) {
        this.$message({
          message: "当前警示图片已添加，请勿重复添加",
          type: "warning",
        });
      } else {
        icons_list.push(row);
        this.$message({
          message: "添加警示图片成功",
          type: "success",
        });
      }
    },
    //删除图片
    on_imgdelete(index) {
      this.formLabelAlign.icons_list.splice(index, 1);
    },
    on_change(val) {
      this.label_from.page = val;
      this.way_labelList();
    },
    on_changecaution(val) {
      this.caution_from.page = val;
      this.way_cautionList();
    },
    dataChange(event) {
      let events = event[event.length - 1];
      this.formLabelAlign.cate_id = events;
    },
    //重置表单
    on_reset(FromData) {
      FromData.id = "";
      FromData.name = "";
      FromData.is_enable = "";
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));

          let { icons_list, tags_list } = formLabelAlign;
          let icons = icons_list
            .map((obj) => {
              return obj.id;
            })
            .join(",");
          let tags = tags_list
            .map((obj) => {
              return obj.id;
            })
            .join(",");
          delete formLabelAlign.tags_list;
          delete formLabelAlign.icons_list;
          formLabelAlign.icons = icons;
          formLabelAlign.tags = tags;
          pointsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  width: 100%;
  height: 100%;
}
.img-div {
  position: relative;
  margin: 0 10px 10px 0;
  width: 80px;
  height: 80px;
}

.sty-imgdelete {
  position: absolute;
  right: 0;
  top: 0;
  background: #e0e0e0;
  padding: 3px;
  color: #ffffff;
}
</style>